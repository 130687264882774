<script lang="ts" setup>
import infoSvg from '@/assets/info.svg'

interface Props {
  onlyRead?: boolean
  isDetail?: boolean
  attrName?: string
  pageType?: string
  isClickTrigger?: boolean
  columns?: number
}

const props = withDefaults(defineProps<Props>(), {
  onlyRead: false,
  isDetail: false,
  attrName: '',
  pageType: '',
  isClickTrigger: false,
  columns: 1,
})

const isMobile = useMobileByScreen()
const { t } = useI18n()
const commonStore = useCommonStore()
const useInfoStore = useUserStore()
const filterConditionsStore = useFilterConditionsStore()

const tagList = ref<string[]>(props.isDetail ? filterConditionsStore.filterConditions.detailFilterIdList : filterConditionsStore.filterConditions.filterIdList)
const FilterOptionsList = computed(() => commonStore.commonData.filterOpitonsInfo?.filters?.filter(
  item => !props.isDetail || item.category !== '1003',
) || [])

const priceRange = computed(() => {
  const opiton = (commonStore.commonData.filterOpitonsInfo?.priceRanges || []).find(item => item.currency === useInfoStore.userInfo.currency)
  return opiton || {}
})

const sliderValue = ref<number[]>([priceRange?.value?.currency === filterConditionsStore.filterConditions.range.currency ? filterConditionsStore.filterConditions.range.min : priceRange?.value.min || 0, priceRange?.value?.currency === filterConditionsStore.filterConditions.range.currency ? filterConditionsStore.filterConditions.range.max : priceRange?.value?.max || 100])
function isSubArray(subArray: string[], mainArray: string[]) {
  return subArray.every(item => mainArray.includes(item))
}
function setDisabled(id: string) {
  const arr = ['1001_2', '1001_1']
  if (isSubArray(arr, tagList.value)) {
    return false
  }
  if (arr.includes(id) && tagList.value.includes(id)) {
    return true
  }
  return false
}

function onConfirm() {
  if (props.isDetail) {
    onDetailConfirm()
  }
  else {
    onListConfirm()
  }
}

function onListConfirm() {
  filterConditionsStore.filterConditions.filterIdList = tagList.value
  filterConditionsStore.setRange({
    min: sliderValue.value[0],
    max: sliderValue.value[1],
    currency: useInfoStore.userInfo.currency,
  })
}

function onDetailConfirm() {
  filterConditionsStore.filterConditions.detailFilterIdList = tagList.value
}

defineExpose({
  onConfirm,
})

watch(() => useInfoStore.userInfo.currency, (newVal) => {
  if (newVal === filterConditionsStore.filterConditions.range.currency) {
    sliderValue.value = [filterConditionsStore.filterConditions.range.min, filterConditionsStore.filterConditions.range.max]
  }
  else {
    sliderValue.value = [priceRange?.value?.min || 0, priceRange?.value?.max || 100]
  }
})

function changeHandle() {
  if (props.isClickTrigger) {
    onConfirm()
  }
}

function openPolicy() {
  showDialog({
    showConfirmButton: false,
    closeOnClickOverlay: true,
    messageAlign: 'left',
    title: t('key.experience.cancellation.policy'),
    message: t('key.filter.cancellation.tooltip'),
  })
}
</script>

<template>
  <div>
    <div class="min-w-60">
      <div v-if="!isDetail" p="x-4 y-3">
        <div class="pb-1 text-16px text-[#121212] font-500">
          {{ t('key.attractions.list.price') }}
        </div>
        <div text="#888888">
          <span> {{ commonStore.getCurrencySymbol }}{{ sliderValue[0] }} </span> - <span>{{ commonStore.getCurrencySymbol }}{{ sliderValue[1] }}</span> +
        </div>
        <div p="x-2">
          <ElSlider v-model="sliderValue" range :min="priceRange.min || 0" :max="priceRange.max || 100" :show-tooltip="false" @change="changeHandle" />
        </div>
      </div>
      <van-checkbox-group v-model="tagList" class="w-full" icon-size="1.25rem" @click="changeHandle">
        <template v-for="option in FilterOptionsList" :key="option.title">
          <div p="b-1" font="600" text="base #121212">
            {{ option.title }}
          </div>
          <div class="checkbox-list mb-4" :style="{ gridTemplateColumns: `repeat(${columns}, 1fr)` }">
            <BaseAZCheckBox
              v-for="item in option.options"
              :key="`${item.category}_${item.optionCode}`"
              :name="`${item.category}_${item.optionCode}`"
              :disabled="setDisabled(`${item.category}_${item.optionCode}`)"
              class="box-border w-full flex items-center py-1.5"
            >
              <span class="text-sm text-[#121212]">{{ item.optionName }}</span>
              <!-- 1004_1: Free cancellation -->
              <template v-if="item.optionCode === '1004_1'">
                <img v-if="isMobile" w="4" h="4" :src="infoSvg" m="l-1" @click.stop="openPolicy" />
                <el-tooltip
                  v-else
                  :content="t('key.filter.cancellation.tooltip')"
                  placement="top"
                  effect="light"
                >
                  <img w="4" h="4" :src="infoSvg" m="l-1" />
                </el-tooltip>
              </template>
            </BaseAZCheckBox>
          </div>
        </template>
      </van-checkbox-group>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.checkbox-list {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}
</style>
